.App {
  text-align: center;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
}

* {
  margin: 0;
  padding: 0;
}

.adp-tooltip--component {
  text-transform: none;
  /* max-width: 18.625rem; */
  max-width: 22.625rem;
}
/* 
.label > img {
  cursor: pointer;
} */

/* .tas-form-column > .column-title > img {
  cursor: pointer;
} */

/* body,
html,
#root {
  width: 100%;
  height: 100%;
  background: #fafafa;
} */
